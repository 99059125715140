import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
// import ShopCategory from "../components/ecwid/category"

class Shipping extends React.Component {
	componentDidMount() {
		if (window) {
			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = `https://forms.webriq.com/js/initForms`
			const headScript = document.getElementsByTagName('script')[0]
			headScript.parentNode.insertBefore(script, headScript)
		}
	}
	render() {
		let params = this.props.location.pathname
		return (
			<Layout
				pathname={params}
				head={{
					name: 'Starboard Shipping Quote -- Full Uncut Sheets -- Retail',
					meta: {
						description:
							'Starboard Shipping Quote -- Full Uncut Sheets -- Retail Please use this form to request price & shipping quotes for full uncut sheets of King Starboard.',
					},
				}}
			>
				<div className="container py-5">
					<div className="row">
						{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
						<div className="col-md-8 shop-items mx-auto">
							<div className="page-content">
								<h4 className="section-heading page-mt">
									Starboard Shipping Quote -- Full Uncut Sheets -- Retail
								</h4>
								<p>
									Please use this form to request price & shipping quotes for
									full uncut sheets of King Starboard. If you also need
									half-sheet quantities, we can add those to your full-sheet
									shipment. Simply describe your requiremens where indicated
									below.
								</p>
								<p>
									Shipments to a Florida address will be subject to sales tax.
									Truck freight shipments to a residence, walk-in retail shop,
									many marinas, dr's offices etc. are higher than if shipped to
									a business with a loading dock and/or lift truck.
								</p>
								<form
									action="/thank-you"
									method="post"
									data-form-id="5c4ec8e08ba9a30a93a29ffa"
									webriq="true"
								>
									<div className="form-group">
										<label>Your Name:</label>
										<input
											className="form-control"
											type="text"
											name="name"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Your Email: *</label>
										<input
											className="form-control"
											type="text"
											name="email"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Contact Phone Number</label>
										<input
											className="form-control"
											type="text"
											name="phone-number"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Location</label>
										<input
											className="form-control"
											type="text"
											name="location"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Ship-To Zip Code</label>
										<input
											className="form-control"
											type="text"
											name="zip-code"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>
											Standard King Starboard (Quantity & Color For Each Sheet
											Size/Thickness)
										</label>
										<textarea
											className="form-control"
											rows="10"
											name="standard-king-starboard"
										/>
									</div>
									<div className="form-group">
										<label>
											King Starboard AS (Quantity For Each Sheet Size/Thickness)
										</label>
										<textarea
											className="form-control"
											rows="10"
											name="king-starboard-as"
										/>
									</div>
									<div className="form-group">
										<label>Additional Comments, Questions, Information</label>
										<textarea
											className="form-control"
											rows="10"
											name="addtional-comments"
										/>
									</div>
									<div className="webriq-recaptcha mb-3" />
									<div className="form-group">
										<button
											className="btn btn-primary btn-block blue-btn"
											type="submit"
										>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Shipping

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
